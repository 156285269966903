import Error from 'next/error'
import { IS_DEVELOPMENT } from 'src/utils/env'
import { isClient } from 'src/utils/typeguard'

const Custom404 = () => {
  if (IS_DEVELOPMENT && isClient()) return <Error statusCode={404} />
  if (isClient()) window.location.replace('/')
  return <></>
}

export default Custom404
